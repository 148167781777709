
    .TestColumn-content{
        position: relative;
        right: -10px;
        top: 164px;
        height: 60px;
        z-index: 999;
        .TestColumn-title{
            cursor: pointer;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            align-items: center;
            -webkit-align-items: center;
            background: #fff;
            width: 48px;
            border-radius: 8px;
            font-size: 12px;
            .TestColumn-Icon{
                display: inline-block;
                height: 32px;
                line-height: 32px;
                -webkit-text-fill-color: #409eff;
                color: #409eff;
                cursor: pointer;
                i{
                    font-size: 26px;
                }
            }
        }
        .TestColumn-dialog{
            min-width: 300px;
            position: absolute;
            right: 54px;
            top: 48px;
            border: 1px solid #409eff;
            text-align: left;
            color: #333;
            .TestColumn-dialog-top{
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                color: #fff;
                padding: 0 10px;
                background: #409eff;
            }
            .TestColumn-dialog-body{
                background-color: #fff;
                .TestColumn-dialog-num{
                    font-size: 16px;
                    color: #333;
                    padding: 10px 10px;
                     em {
                        font-style: normal;
                        color: #409eff;
                    }
                }
                .TestColumn-dialog-type{
                    font-size: 12px;
                    padding: 8px 10px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    border-bottom: 1px dashed #ddd;
                    .TestColumn-dialog-del{
                        color: #409eff;
                        cursor: pointer;
                        border: none;
                        background: #fff;
                        &:hover .TestColumn-dialog-del{
                            color: #66b1ff;
                        }
                    }
                }
                .TestColumn-dialog-add {
                    display: -webkit-box;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    padding: 20px;
                    font-size: 14px;
                }
            }
            .TestColumn-dialog-foot{
                display: -webkit-box;
                display: flex;
                -webkit-box-pack: start;
                justify-content: start;
                -webkit-box-align: center;
                align-items: center;
                font-size: 14px;
                /*padding: 0 10px;*/
                height: 45px;
                background: #f5f9ff;
                .TestColumn-dialog-foot-left{
                    display: -webkit-box;
                    display: flex;
                    justify-content: flex-end;
                    -webkit-justify-content: flex-end;
                    -webkit-box-flex: 1;
                    flex: 1;
                    width: 1%;
                    span {
                        margin-right: 20px;
                        color: #409eff;
                        cursor: pointer;
                    }
                }
                .preview-btn{
                    padding: 6px 4px;
                }
            }

        }
    }
